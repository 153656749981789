import React from "react";
import { styled } from "@mui/material/styles";
import Header from "./Header";
import Footer from "./Footer";
import CartPopup from "../Cart/Cart";

const RootStyle = styled("div")({});

const MainStyle = styled("main")(() => ({
  // marginLeft: 55,
}));

const Layout = ({ children }: React.PropsWithChildren) => {
  return (
    <RootStyle>
      <Header />
      <CartPopup />
      <MainStyle>{children}</MainStyle>
      <Footer />
    </RootStyle>
  );
};

export default Layout;
