import { Product } from "../../types/woocommerce";

export const localStorageCartItem = "FleurilandLocalCart";

export interface Item {
  product: Product;
  quantity: number;
}
export enum ShippingMethodIdEnum {
  CLICKANDCOLLECT = "local_pickup",
  LIVRAISON = "flat_rate",
  LIVRAISON_GRATUITE = "free_shipping",
}

export interface ShippingMethod {
  id: ShippingMethodIdEnum;
  title: string;
  description: string;
  price?: string;
}

export enum ShopActionType {
  ADD_ITEM,
  REMOVE_ITEM,
  SET_ITEM_QUANTITY,
  TRASH_ITEM,
  TRASH_CART,
  LOAD_SHOP,
  INIT_SHOP,
  SET_STATE,
}

type ItemAction = {
  type:
    | ShopActionType.ADD_ITEM
    | ShopActionType.SET_ITEM_QUANTITY
    | ShopActionType.REMOVE_ITEM
    | ShopActionType.TRASH_ITEM;
  payload: {
    item: Item;
  };
};

type CartAction = {
  type: ShopActionType.TRASH_CART;
  payload?: null;
};

type StateAction = {
  type: ShopActionType.SET_STATE;
  payload: Partial<ShopState>;
};
type GlobalAction = {
  type: ShopActionType.LOAD_SHOP | ShopActionType.INIT_SHOP;
  payload?: null;
};

export type ShopAction = ItemAction | CartAction | StateAction | GlobalAction;

export interface ShopState {
  items: Item[];
  coupons: any[];
  shippingMethodId: ShippingMethodIdEnum;
  isDifferentAddress: boolean;
  shippingAddress: {
    first_name: string;
    last_name: string;
    company: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
  };
  billingAddress: {
    first_name: string;
    last_name: string;
    company: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
    email: string;
    phone: string;
  };
}

export const initialShopState: ShopState = {
  items: [],
  coupons: [],
  shippingMethodId: ShippingMethodIdEnum.CLICKANDCOLLECT,
  isDifferentAddress: false,
  shippingAddress: {
    first_name: "",
    last_name: "",
    company: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
  },
  billingAddress: {
    first_name: "",
    last_name: "",
    company: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    email: "",
    phone: "",
  },
};
