import Head from "next/head";
import { useRouter } from "next/router";
import config from "../../config";
import { formatExcerpt } from "../../utils/formatExcerpt";

const DEFAULT_OG_IMAGE = config.siteUrl + "/img/seo/3.jpeg";

interface SeoProps {
  title?: string;
  description?: string;
  siteName?: string;
  canonical?: string;
  ogImage?: string;
  ogType?: "website" | "article" | "music" | "video";
  twitterHandle?: string;
}
export default function Seo(props: SeoProps) {
  const { pathname } = useRouter();
  const extractedPath = pathname.match(/^\/(\w+)/)?.[1].toLocaleLowerCase();
  const title =
    props.title ??
    (!!extractedPath
      ? extractedPath.charAt(0).toUpperCase() + extractedPath.slice(1)
      : "Jardinerie Fleuriland");
  const description =
    formatExcerpt(props.description) ??
    "Jardinerie - Paysagisme - Fleuristerie en Agde";
  const siteName = props.siteName ?? "Fleuriland";
  const canonical = props.canonical ?? `${config.siteUrl}${pathname}`;
  const ogImage = props.ogImage ?? DEFAULT_OG_IMAGE;
  const ogType = props.ogType ?? "website";
  return (
    <Head>
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <title key="title">{`${title} | ${siteName}`}</title>
      <meta name="description" content={description} />
      <meta key="og_type" property="og:type" content={ogType} />
      <meta key="og_title" property="og:title" content={title} />
      <meta
        key="og_description"
        property="og:description"
        content={description}
      />
      {/* <meta key="og_locale" property="og:locale" content="en_IE" /> */}
      <meta key="og_site_name" property="og:site_name" content={siteName} />
      <meta
        key="og_url"
        property="og:url"
        content={canonical ?? config.siteUrl}
      />
      <meta key="og_site_name" property="og:site_name" content={siteName} />
      <meta
        key="og_image"
        property="og:image"
        content={ogImage ?? DEFAULT_OG_IMAGE}
      />
      <meta
        key="og_image:alt"
        property="og:image:alt"
        content={`${title} | ${siteName}`}
      />
      <meta key="og_image:width" property="og:image:width" content="1200" />
      <meta key="og_image:height" property="og:image:height" content="630" />

      <meta name="robots" content="index,follow" />

      {/* <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        key="twitter:site"
        name="twitter:site"
        content={twitterHandle}
      />
      <meta
        key="twitter:creator"
        name="twitter:creator"
        content={twitterHandle}
      />
      <meta
        key="twitter:title"
        property="twitter:title"
        content={title}
      />
      <meta
        key="twitter:description"
        property="twitter:description"
        content={description}
      /> */}

      <link rel="canonical" href={canonical} />
      <link rel="shortcut icon" href="/favicon.ico" />
    </Head>
  );
}
