import { useTheme } from "@mui/material";
import Link from "next/link";

const NextLink = ({
  children,
  url,
  ...props
}: React.PropsWithChildren<{ url: string } & any>) => {
  const theme = useTheme();
  const { style, ...otherProps } = props;
  return (
    <Link
      href={url}
      style={{
        textDecoration: "none",
        color: theme.palette.text.primary,
        ...style,
      }}
      {...otherProps}
    >
      {children}
    </Link>
  );
};

export default NextLink;
