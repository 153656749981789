import * as React from "react";
import { AppProps as NextAppProps } from "next/app";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, EmotionCache } from "@emotion/react";
import theme from "../config/theme";
import createEmotionCache from "../config/createEmotionCache";
import Layout from "../components/Layout";
import { NextPage } from "next";
import "../lib/i18n";

import "swiper/css/bundle";
import "src/components/sliders/custom.css";

import { Maven_Pro } from "@next/font/google";
import Script from "next/script";
import ShopContextProvider from "../context/shop/shop.context";
import { ApolloProvider } from "@apollo/client";
import { apolloWpClient } from "../lib/graphql";
import ErrorBoundary from "../components/errors/ErrorBoundary";
import { MySnackbarProvider } from "../hooks/useMySnackbar";
import dynamic from "next/dynamic";
import Seo from "../components/others/Seo";
import config from "../config";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
  public?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

interface AppProps extends NextAppProps {
  emotionCache: EmotionCache;
  pageProps: any;
}

const CrispWithNoSSR = dynamic(() => import("../lib/crisp"), { ssr: false });

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props: AppPropsWithLayout) {
  if (typeof window !== "undefined") {
    (window as any).axeptioSettings = config.axeptio;
  }

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);
  return (
    <>
      <Seo />
      <Script src="//static.axept.io/sdk.js" />
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-K46KCC5');
        `}
      </Script>
      <CrispWithNoSSR />

      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <ApolloProvider client={apolloWpClient}>
              <MySnackbarProvider autoHideDuration={8000}>
                <ShopContextProvider>
                  {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                  <CssBaseline />
                  {getLayout(<Component {...pageProps} />)}
                </ShopContextProvider>
              </MySnackbarProvider>
            </ApolloProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}
