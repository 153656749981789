import { getLogger } from "../../utils/logger/pino";
import {
  initialShopState,
  Item,
  localStorageCartItem,
  ShopState,
} from "./shop.types";

const logger = getLogger("Shop actions");

export const initShop = () => {
  localStorage.removeItem("FleurilandLocalCart");
  return initialShopState;
};

export const loadShop = () => {
  const cachedCart = localStorage.getItem(localStorageCartItem);
  if (cachedCart) {
    try {
      const cart = JSON.parse(cachedCart) as ShopState;
      return cart;
    } catch (e) {
      logger.error("Cart seems broken, resetting");
      return initShop();
    }
  } else return initShop();
};

export const saveShop = (newState: ShopState) => {
  try {
    localStorage.setItem(localStorageCartItem, JSON.stringify(newState));
    return newState;
  } catch (e) {
    logger.error("Error while saving cart : ", e);
    return initShop();
  }
};

export const addItem = (state: ShopState, item: Item) => {
  const items = [...state.items];
  const sameItem = items.find(({ product }) => product.id === item.product.id);
  const newItems = [
    ...items.filter(({ product }) => product.id !== item.product.id),
    {
      product: item.product,
      quantity: item.quantity + (sameItem?.quantity ?? 0),
    },
  ];
  return {
    ...state,
    items: newItems,
  };
};

export const setItemQuantity = (state: ShopState, item: Item) => {
  const items = [...state.items];
  const newItems = [
    ...items.filter(({ product }) => product.id !== item.product.id),
    {
      product: item.product,
      quantity: item.quantity,
    },
  ];
  return {
    ...state,
    items: newItems,
  };
};
export const removeItem = (state: ShopState, productId: number) => {
  const items = [...state.items];
  const foundItem = items.find(({ product }) => product.id === productId);
  if (foundItem) {
    const newItems = [
      ...items.filter(({ product }) => product.id !== productId),
      {
        product: foundItem.product,
        quantity: foundItem.quantity - 1,
      },
    ];
    return {
      ...state,
      items: newItems,
    };
  } else return state;
};

export const trashItem = (state: ShopState, productId: number) => {
  const newItems = [...state.items];
  const idx = newItems.findIndex((el) => el.product.id === productId);
  newItems.splice(idx, 1);
  return {
    ...state,
    items: idx === -1 ? state.items : newItems,
  };
};
