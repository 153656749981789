import {
  SnackbarProvider as NotistackProvider,
  SnackbarAction,
} from "notistack";
import { useSnackbar as useNotistack } from "notistack";
import { useCallback } from "react";

export const useMySnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotistack();

  const displayDefaultSnackbar = useCallback(
    (message: string) => enqueueSnackbar(message, { variant: "default" }),
    [enqueueSnackbar]
  );
  const displaySuccessSnackbar = useCallback(
    (message: string, action?: SnackbarAction) =>
      enqueueSnackbar(message, { variant: "success", action }),
    [enqueueSnackbar]
  );
  const displayErrorSnackbar = useCallback(
    (message: string, action?: SnackbarAction) =>
      enqueueSnackbar(message, { variant: "error", action }),
    [enqueueSnackbar]
  );
  const displayWarningSnackbar = useCallback(
    (message: string, action?: SnackbarAction) =>
      enqueueSnackbar(message, { variant: "warning", action }),
    [enqueueSnackbar]
  );
  const displayInfoSnackbar = useCallback(
    (message: string, action?: SnackbarAction) =>
      enqueueSnackbar(message, { variant: "info", action }),
    [enqueueSnackbar]
  );

  return {
    displayDefaultSnackbar,
    displaySuccessSnackbar,
    displayErrorSnackbar,
    displayWarningSnackbar,
    displayInfoSnackbar,
    closeSnackbar,
  };
};

export const MySnackbarProvider = NotistackProvider;
