import { ApolloClient, InMemoryCache } from "@apollo/client";
import config from "../../config";

// export const apolloClient = new ApolloClient({
//   uri: "http://127.0.0.1:1337/graphql",
//   cache: new InMemoryCache(),
// });

// protect with auth

export const apolloWpClient = new ApolloClient({
  uri: config.cmsUrl,
  cache: new InMemoryCache({}),
});
