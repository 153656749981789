import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { getLogger } from "../../utils/logger/pino";
import { shopReducer } from "./shop.reducer";
import {
  initialShopState,
  ShippingMethod,
  ShopAction,
  ShopActionType,
  ShopState,
} from "./shop.types";

const logger = getLogger("Shop context");

interface ShopContextProps {
  openCart: boolean;
  setOpenCart: Dispatch<SetStateAction<boolean>>;
  shopState: ShopState;
  nItems: number;
  subTotal: number;
  shopDispatch: Dispatch<ShopAction>;
  initShop: () => void;
  shippingMethods: ShippingMethod[];
}

export const ShopContext = createContext<ShopContextProps>({
  shopState: initialShopState,
} as ShopContextProps);
export const useShopContext = () => useContext(ShopContext);

const ShopContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [openCart, setOpenCart] = useState(false);
  const [shopState, shopDispatch] = useReducer(shopReducer, initialShopState);
  const initShop = () => shopDispatch({ type: ShopActionType.INIT_SHOP });
  useEffect(() => {
    shopDispatch({ type: ShopActionType.LOAD_SHOP });
  }, []);
  const nItems = shopState.items.reduce((prev, cur) => prev + cur.quantity, 0);
  const subTotal = shopState.items.reduce(
    (prev, cur) => prev + parseFloat(cur.product.price) * cur.quantity,
    0
  );
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>([]);
  useEffect(() => {
    const load = async () => {
      try {
        const ret = await fetch("/api/shop/shipping_methods", {
          method: "GET",
        });
        if (ret.status !== 200)
          throw new Error("GET /api/shop/shipping_methods : not a 200 status");
        const response = await ret.json();
        setShippingMethods(response);
      } catch (e) {
        // TODO: add error handling
        setShippingMethods([]);
        logger.error("Error: ", e);
      }
    };
    load();
  }, [shopState]);
  return (
    <ShopContext.Provider
      value={{
        openCart,
        setOpenCart,
        shopState,
        nItems,
        subTotal,
        shopDispatch,
        initShop,
        shippingMethods,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
