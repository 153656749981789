import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import frTranslation from "./locales/fr/translation.json";

i18n.use(initReactI18next).init({
  fallbackLng: "fr",
  // debug: true,
  resources: {
    fr: { translation: frTranslation },
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
