const config = {
  woocommerceClientKey: process.env.WOOCOMMERCE_CLIENT_KEY,
  woocommerceClientSecret: process.env.WOOCOMMERCE_SECRET_KEY,
  shopUrl: "https://cms.fleuriland.fr/wp-json/wc/v3/",
  cmsUrl: "https://cms.fleuriland.fr/graphql",
  telephone: "+33 6 09 88 53 09",
  crispId: process.env.NEXT_PUBLIC_CRISP_ID,
  siteUrl:
    process.env.NODE_ENV === "production"
      ? "https://fleuriland.fr"
      : "https://localhost:3000",
  axeptio: {
    clientId: process.env.NEXT_PUBLIC_AXEPTIO_ID,
    cookiesVersion: "fleuriland-fr",
  },
};

export default config;
