import { Box, Container, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import NextLink from "../../others/NextLink";
import config from "../../../config";

const Footer = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        pt: { md: 5 },
      }}
    >
      <Container
        maxWidth="lg"
        disableGutters
        // sx={{ height: theme.dimensions.footerHeight }}
      >
        <Stack>
          <Stack
            sx={{
              alignItems: "flex-start",
              alignSelf: { md: "flex-end", xs: "center" },
              my: 3,
            }}
          >
            <Typography
              sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
            >
              FLEURILAND
            </Typography>
            <Typography
              sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
            >
              139 route de Rochelongue
            </Typography>
            <Typography
              sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
            >
              34300 AGDE
            </Typography>
            <Typography
              sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
            >
              {config.telephone}
            </Typography>
            <Typography
              sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
            >
              contact@fleuriland.fr
            </Typography>
          </Stack>
          <Stack
            flexDirection={{ md: "row", xs: "column" }}
            alignItems="center"
            sx={{
              borderWidth: "1px 0px 0px 0px",
              borderColor: theme.palette.divider,
              borderStyle: "solid",
            }}
          >
            <Stack flex="1" alignItems={{ md: "flex-start", xs: "center" }}>
              <Typography variant="caption">
                © 2023 FLEURILAND. ALL RIGHTS RESERVED.
              </Typography>
              <Typography
                variant="caption"
                sx={{ textAlign: { sm: "left", xs: "center" } }}
              >
                AGRÉMENT PHYTOSANITAIRE APPLICATION ET DISTRIBUTION LR00917
              </Typography>
            </Stack>
            <Stack
              justifyContent={{ md: "flex-end", xs: "center" }}
              flexDirection="row"
              flex="1"
              sx={{
                "& .MuiTypography-root": {
                  mx: 1,
                  "&, &:visited, &:hover, &:active": {
                    color: theme.palette.text.primary,
                  },
                },
                " & a": {
                  textDecoration: "none",
                },
              }}
            >
              <NextLink href="/legal/cgv">
                <Typography align="right">
                  {t("footer.cgv").toUpperCase()}
                </Typography>
              </NextLink>
              |
              <NextLink href="/legal/mentions">
                <Typography align="right">
                  {t("footer.legal").toUpperCase()}
                </Typography>
              </NextLink>
              |
              <NextLink href="/legal/cookies">
                <Typography align="right">
                  {t("footer.cookies").toUpperCase()}
                </Typography>
              </NextLink>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
