import { Reducer, useReducer } from "react";
import {
  addItem,
  initShop,
  loadShop,
  trashItem,
  saveShop,
  removeItem,
  setItemQuantity,
} from "./shop.actions";
import { ShopAction, ShopActionType, ShopState } from "./shop.types";

export const shopReducer = (state: ShopState, action: ShopAction) => {
  const { type, payload } = action;
  let newState: ShopState;
  switch (type) {
    case ShopActionType.ADD_ITEM:
      newState = addItem(state, payload.item);
      break;
    case ShopActionType.SET_ITEM_QUANTITY:
      newState = setItemQuantity(state, payload.item);
      break;
    case ShopActionType.TRASH_ITEM:
      newState = trashItem(state, payload.item.product.id);
      break;
    case ShopActionType.REMOVE_ITEM:
      newState = removeItem(state, payload.item.product.id);
      break;
    case ShopActionType.TRASH_CART: {
      newState = {
        ...state,
        items: [],
        coupons: [],
      };
      break;
    }
    case ShopActionType.LOAD_SHOP: {
      newState = loadShop();
      break;
    }
    case ShopActionType.INIT_SHOP: {
      newState = initShop();
      break;
    }
    case ShopActionType.SET_STATE: {
      newState = { ...state, ...payload };
      break;
    }
    default:
      newState = state;
  }
  return saveShop(newState);
};
