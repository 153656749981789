import * as React from "react";
import logoFleuriland from "../../../../public/img/logo-site.png";
import { useState } from "react";
import {
  AppBar,
  Box,
  Stack,
  Button,
  Collapse,
  Container,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Menu,
  MenuItem,
  Drawer,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Badge,
  ListItem,
} from "@mui/material";
import Image from "next/image";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import i18next from "../../../lib/i18n";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { ExpandLess, ExpandMore, ShoppingCart } from "@mui/icons-material";
import { useShopContext } from "../../../context/shop/shop.context";
import { css } from "@emotion/react";
import NextLink from "../../others/NextLink";

const navigationLinks = [
  {
    name: i18next.t("header.catalogue"),
    href: "/jardinerie-agde-catalogue",
  },
  {
    name: i18next.t("header.jardinerie"),
    href: "/jardinerie",
  },
  {
    name: i18next.t("header.services.menu"),
    href: "/",
  },

  {
    name: i18next.t("header.blog"),
    href: "/blog",
  },
  {
    name: i18next.t("header.shop"),
    href: "/shop",
  },
];

const Header = ({ elevation = 0 }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const router = useRouter();
  const { nItems } = useShopContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sideMenuOpen, setSideMenuOpen] = React.useState(false);
  const matchMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const toggleSideMenuOpen = () => {
    setSideMenuOpen((open) => !open);
  };

  const DesktopMenu = (
    <Stack
      sx={{
        flex: 1.5,
        display: "flex",
        justifyContent: "space-between",
        width: "80%",
        py: 2,
        alignItem: "center",
        flexDirection: "row",
        "& .MuiButton-root": {
          minWidth: theme.spacing(15),
        },
        "& .MuiButtonBase-root:hover .MuiTypography-root": {
          color: theme.palette.primary.dark,
        },
      }}
    >
      <Button
        sx={{
          textTransform: "none",
          flex: 1,
          display: "flex",
          alignItems: "center",
          color: theme.palette.text.primary,
        }}
      >
        <NextLink href={navigationLinks[0].href}>
          <Typography
            align="center"
            sx={{
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            {navigationLinks[0].name}
          </Typography>
        </NextLink>
      </Button>

      <Button
        onClick={handleOpenMenu}
        sx={{
          textTransform: "none",
          flex: 1,
          display: "flex",
          alignItems: "center",
          color: theme.palette.text.primary,
        }}
      >
        <Typography
          align="center"
          sx={{
            flex: 1,
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          {navigationLinks[1].name}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow:
              "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
          },
          "& .MuiMenuItem-root:hover .MuiTypography-root": {
            color: theme.palette.primary.dark,
          },
        }}
      >
        <MenuItem sx={{ width: anchorEl?.scrollWidth }}>
          <Typography
            align="center"
            sx={{
              flex: 1,
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            {t("header.services.paysagisme")}
          </Typography>
        </MenuItem>
        <MenuItem sx={{ width: anchorEl?.scrollWidth }}>
          <Typography
            align="center"
            sx={{
              flex: 1,
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            {t("header.services.entretiens")}
          </Typography>
        </MenuItem>
        <MenuItem sx={{ width: anchorEl?.scrollWidth }}>
          <Typography
            align="center"
            sx={{
              flex: 1,
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            {t("header.services.fleuriste")}
          </Typography>
        </MenuItem>
      </Menu>

      <Button
        sx={{
          textTransform: "none",
          flex: 1,
          display: "flex",
          alignItems: "center",
          color: theme.palette.text.primary,
        }}
      >
        <NextLink href={navigationLinks[2].href} style={{ flex: 1 }}>
          <Typography
            align="center"
            sx={{
              flex: 1,
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            {navigationLinks[2].name}
          </Typography>
        </NextLink>
      </Button>
      <Button
        sx={{
          textTransform: "none",
          flex: 1,
          display: "flex",
          alignItems: "center",
          color: theme.palette.text.primary,
        }}
      >
        <NextLink href={navigationLinks[3].href} style={{ flex: 1 }}>
          <Typography
            align="center"
            sx={{
              flex: 1,
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            {navigationLinks[3].name}
          </Typography>
        </NextLink>
      </Button>
    </Stack>
  );

  return (
    <>
      <AppBar
        sx={{ background: theme.palette.background.default }}
        position="sticky"
        elevation={elevation}
      >
        <Container maxWidth="lg" disableGutters>
          <Toolbar
            sx={{
              height: {
                xs: theme.dimensions.headerHeightXs,
                md: theme.dimensions.headerHeight,
              },
              flexDirection: "column",
            }}
            disableGutters
          >
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                py: 2,
                px: 2,
                borderStyle: { md: "solid", xs: "none" },
                borderWidth: "0px 0px 1px 0px",
                borderBottomColor: theme.palette.divider,
              }}
            >
              <Stack alignItems="flex-start" sx={{ justifyContent: "center" }}>
                <NextLink href="/">
                  <Image
                    alt="Fleuriland logo"
                    src={logoFleuriland}
                    width={matchMdUp ? 150 : 100}
                  />
                </NextLink>
              </Stack>
              <Stack
                flexDirection="row"
                sx={{
                  justifyContent: { xs: "center", md: "flex-end" },
                  px: { xs: 0, md: 2 },
                }}
                flex={1}
              >
                <Button
                  sx={
                    {
                      // textTransform: "none",
                    }
                  }
                  variant="contained"
                >
                  <NextLink href="/contact">
                    <Typography
                      align="center"
                      sx={{
                        flex: 1,
                        fontWeight: theme.typography.fontWeightBold,
                        fontSize: {
                          xs: 12,
                          md: theme.typography.button.fontSize,
                        },
                        color: theme.palette.primary.contrastText,
                      }}
                    >
                      {t("header.contact")}
                    </Typography>
                  </NextLink>
                </Button>
              </Stack>
              <Stack flexDirection="row" justifyContent="flex-end">
                <IconButton
                  onClick={() => {
                    router.push(nItems === 0 ? "/shop" : "/shop/cart");
                  }}
                >
                  <Badge badgeContent={nItems} color="secondary">
                    <ShoppingCart />
                  </Badge>
                </IconButton>
                <IconButton
                  onClick={() => setOpen(true)}
                  sx={{ display: { md: "none", xs: "flex" } }}
                >
                  <MenuIcon />
                </IconButton>
              </Stack>
            </Stack>

            <Stack
              sx={{
                display: { md: "flex", xs: "none" },
                width: "100%",
                alignItems: "center",
              }}
            >
              {DesktopMenu}
            </Stack>
          </Toolbar>
        </Container>

        <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
          <div onClick={() => setOpen(false)} role="button" tabIndex={0}>
            <IconButton>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <NextLink href={navigationLinks[0].href}>
              <ListItemButton onClick={() => setOpen(false)}>
                {navigationLinks[0].name}
              </ListItemButton>
            </NextLink>
            <ListItemButton onClick={toggleSideMenuOpen}>
              {navigationLinks[1].name}
              {sideMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={sideMenuOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ pl: 2 }}>
                <ListItemButton>
                  {t("header.services.paysagisme")}
                </ListItemButton>
                <ListItemButton>
                  {t("header.services.entretiens")}
                </ListItemButton>
                <ListItemButton>
                  {t("header.services.fleuriste")}
                </ListItemButton>
              </List>
            </Collapse>
            <NextLink href={navigationLinks[2].href}>
              <ListItemButton onClick={() => setOpen(false)}>
                {navigationLinks[2].name}
              </ListItemButton>
            </NextLink>
            <NextLink href={navigationLinks[3].href}>
              <ListItemButton onClick={() => setOpen(false)}>
                {navigationLinks[3].name}
              </ListItemButton>
            </NextLink>
          </List>
        </Drawer>
      </AppBar>
    </>
  );
};

export default Header;
