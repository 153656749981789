import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { WithRouterProps } from "next/dist/client/with-router";
import { withRouter } from "next/router";
import React from "react";
import { initShop } from "../../context/shop/shop.actions";
import * as Sentry from "@sentry/nextjs";

interface ErrorBoundaryProps extends React.PropsWithChildren, WithRouterProps {}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  public state: { hasError: boolean };
  constructor(props: ErrorBoundaryProps) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    Sentry.captureException(error, errorInfo);
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container maxWidth="lg">
          <Box sx={{ xs: "none" }}></Box>
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "20vh",
              width: "100%",
            }}
          >
            <Typography variant="h2" pb={2}>
              Désolé, il y a eu une erreur
            </Typography>
            <Stack flexDirection="row">
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({ hasError: false });
                  initShop();
                  this.props.router.push("/");
                }}
              >
                Retour à la page d&apos;acceuil
              </Button>
            </Stack>
          </Stack>
        </Container>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
