import {
  ClickAwayListener,
  Drawer,
  SwipeableDrawer,
  Typography,
  useTheme,
} from "@mui/material";
import { useShopContext } from "../../context/shop/shop.context";

interface CartProps {}

const CartPopup = (props: CartProps) => {
  const theme = useTheme();
  const { openCart, setOpenCart } = useShopContext();

  const handleClose = () => setOpenCart(false);
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => openCart && handleClose()}
    >
      <Drawer
        open={openCart}
        variant="persistent"
        anchor="right"
        PaperProps={{
          sx: {
            top: theme.dimensions.headerHeight,
            width: "40vw",
          },
        }}
        sx={{ maxWidth: 100 }}
      >
        <Typography>Coucou</Typography>
      </Drawer>
    </ClickAwayListener>
  );
};

export default CartPopup;
