import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { Maven_Pro } from "@next/font/google";
// Create a theme instance.

const mavenPro = Maven_Pro({ subsets: ["latin"] });

const theme = createTheme({
  dimensions: {
    headerHeight: 150,
    headerHeightXs: 70,
    footerHeight: 300,
  },
  palette: {
    divider: "#7A571D",
    background: {
      layout: "#EED9B7",
    },
    primary: {
      main: "#AB7928",
    },
    neutral: {
      main: "#FFF",
    },
    secondary: {
      main: "#2885A9",
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: "#000",
    },
  },
  typography: {
    fontFamily: mavenPro.style.fontFamily, // "Maven Pro",
  },
});
export default theme;
